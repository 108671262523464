/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/
[data-elm-hot="true"] {
  height: inherit;
}

@media screen and (max-width: 800px) {
    .nomobile {
        display: none;
    }
}

@media screen and (min-width: 801px) {
    .nodesktop {
        display: none;
    }
}

body {
  font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #293c4b;
}

html, body {
    height: 100%;
    width: 100vw;
    overflow-x: hidden;
    touch-action: manipulation;
}

leaflet-map {
    height: 100%;
    width: 100vw;
    /* width: 800px;
    height: 600px; */
    position: absolute;
    outline: none;
    left: 0;
    top: 0;
    /* background-color: #18f; */
}

.leaflet-map {
    position: relative;
    width: 100%;
    height: 100%;
}

h1 {
  font-size: 30px;
}

img {
  margin: 20px 0;
  max-width: 200px;
}

.control {
    position: absolute;
    bottom: 0;
    /* height: 64px; */
    width: 100%;
    left: 0;
    overflow: auto;
    z-index: 1001;
    background-color: rgba(255,255,255,0.5);
}


input.sms_code {
    text-align: center;
}
input.sms_code::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: pink;
    text-align: center;
}
input.sms_code::-moz-placeholder { /* Firefox 19+ */
    color: pink;
    text-align: center;
    align-content: center;
}
input.sms_code:-ms-input-placeholder { /* IE 10+ */
    color: pink;
    text-align: center;
    align-content: center;
}
input.sms_code:-moz-placeholder { /* Firefox 18- */
    color: pink;
    text-align: center;
    align-content: center;
}

dt {
    /* display: inline-block; */
}

dd {
    /* display: inline-block; */
}

.connection {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -30px;
    width: 200px;
    height: 60px;
    background-color: white;
    color: black;
    border: 1px solid black;
    opacity: 1;

}

.head-control {
    color: white;
    float: right;
    margin: 20px;
    font-size: 24px;
    padding: 0;
}

.head-control i {
    margin: 5px;
}

.btn {
    text-transform: initial !important;
}

/* @import './leaflet.scss';*/
